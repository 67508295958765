import React from "react";
import { TitleBar } from "../../Atoms/Titlebar/TitleBar";
import { ProjectIcon1, ProjectIcon2, ProjectIcon3 } from "../../Atoms/svgfile";
import "./work.css";

const ProjectData = [
  {
    id: 1,
    bgColor: "#EDEFF9",
    projectImg: "/images/project-img1.png",
    projectIcon: <ProjectIcon1 />,
    projectTitle: "Vriti.AI",
    projectChip1: "User Research",
    projectPara: "Where you get your dream job",
    projectUrl: "https://vriti.ai/",
    animation: "bounceInRight",
    projectChip: [
      { id: 1, chip: "User Research" },
      { id: 2, chip: "UX Design" },
    ],
  },
  {
    id: 2,
    bgColor: "#F8F0FF",
    projectImg: "/images/project-img2.png",
    projectIcon: <ProjectIcon2 />,
    projectTitle: "ANPR App",
    projectPara: "Fully automated traffic management app",
    projectUrl: "https://www.behance.net/gallery/146420407/ANPR-Cloud-App",
    animation: "bounceInLeft",
    projectChip: [
      { id: 1, chip: "User Research" },
      { id: 2, chip: "UX Design" },
    ],
  },
  {
    id: 3,
    bgColor: "#EBFDFF",
    projectImg: "/images/project-img3.png",
    projectIcon: <ProjectIcon3 />,
    projectTitle: "Cleangic.com",
    projectPara: "Provide a special cleaning & other service at home",
    projectUrl:
      "https://www.behance.net/gallery/146416953/Cleangic-Social-Posts",
    animation: "bounceInRight",
    projectChip: [
      { id: 1, chip: "Graphic Design" },
      { id: 2, chip: "Social Media" },
    ],
  },
];
export const Work = () => {
  return (
    <div className="work-box" id="portfolio">
      <div className="container">
        <TitleBar
          BigTitle="All Creative Works"
          Title="Creative Works"
          Para="Featured projects, which my best projects be choice to show"
        />
        <div>
          {ProjectData.map((data) => {
            return (
              <div
                className={"project-card wow " + data.animation}
                style={{ background: data.bgColor }}
                key={data.id}
              >
                <div className="row">
                  <div className="col-md-5 align-self-center">
                    <div className="project-left">
                      {data.projectIcon}
                      <div className="chip-row">
                        {data.projectChip?.map((chips) => {
                          return (
                            <div className="chip-item" key={chips.id}>
                              {chips.chip}
                            </div>
                          );
                        })}
                      </div>
                      <h1 className="project-title">{data.projectTitle}</h1>
                      <p className="project-para">{data.projectPara}</p>
                      <a
                        href={data.projectUrl}
                        className="btn btn-light text-uppercase"
                        target="_blank"
                      >
                        See Project
                      </a>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="project-right">
                      <img src={data.projectImg} alt="project" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="text-center">
          <a
            href="https://www.behance.net/amanmishra2"
            className="btn btn-primary text-uppercase"
            target="_blank"
          >
            View All
          </a>
        </div>
      </div>
    </div>
  );
};
