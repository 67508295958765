import React from "react";
import { TitleBar } from "../../Atoms/Titlebar/TitleBar";
import { ExpCount } from "../../Atoms/svgfile";
import "./about.css";
const AboutContact = [
  {
    id: 1,
    label: "Name:",
    data: "Aman Mishra",
  },
  {
    id: 2,
    label: "Email:",
    data: "amanmishra8860@gmail.com",
  },
  {
    id: 3,
    label: "Phone:",
    data: "+91-9354851584",
  },
  {
    id: 4,
    label: "From:",
    data: "New Delhi, India",
  },
];

export const About = () => {
  return (
    <div className="About-box" id="about">
      <div className="container">
        <TitleBar
          BigTitle="INTRODUCTION"
          Title="about me"
          Para="A brand today is all about how it makes your customers feel."
        />
        <div className="row">
          <div className="col-md-6 about-left wow fadeIn">
            <h2>
              Hi, I&apos;m <span>Aman Mishra</span>
            </h2>
            <p>
              I&apos;m a designer & developer with a passion for web design. I
              enjoy developing simple, clean and slick websites, apps and
              dashboards that provide real value to the end user. My clients
              have procured exceptional results while working with me.
              Delivering work within time and budget which meets client&apos;s
              requirements is our moto.
            </p>
          </div>
          <div className="col-md-6">
            <div className="about-right text-center wow bounceIn">
              <ExpCount />
              <h2>
                Years of <span>Experience</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="about-contact row">
          {AboutContact.map((contact) => {
            return (
              <div
                className="col-lg-3 col-md-6 wow bounceInUp"
                key={contact.id}
              >
                <div className="py-3">
                  <h5>{contact.label}</h5>
                  <h4>{contact.data}</h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
