import React from "react";
import { TitleBar } from "../../Atoms/Titlebar/TitleBar";
import { Exparrow } from "../../Atoms/svgfile";
import "./expskill.css";

const Skilldata = [
  { img: "/images/figma.png", count: "98%" },
  { img: "/images/css-3.png", count: "86%" },
  { img: "/images/html-5.png", count: "80%" },
  { img: "/images/xd.png", count: "92%" },
  { img: "/images/photoshop.png", count: "73%" },
  { img: "/images/illustrator.png", count: "65%" },
];

const Expdata = [
  {
    id: 1,
    expLink: "https://www.linkedin.com/company/marktine/  ",
    expImg: "/images/marktine.png",
    expTitle: "Marktine Technology Solutions Pvt Ltd",
    expRole: "Senior UI/UX Web Designer",
    expDate: "January 2022 - Present",
  },
  {
    id: 2,
    expLink: "https://www.linkedin.com/company/intozi/",
    expImg: "/images/intozi.png",
    expTitle: "Intozi Tech Pvt Ltd",
    expRole: "UI/UX Web Designer",
    expDate: "December 2020 - January 2022",
  },
  {
    id: 3,
    expLink:
      "https://www.facebook.com/people/Bailiwick-Tech-Innovation-Pvt-Ltd/100067960792340/",
    expImg: "/images/bailiwick.jpg",
    expTitle: "Bailiwick Tech Innovation Pvt Ltd",
    expRole: "UI/UX Web Designer",
    expDate: "April 2019 - October 2020",
  },
];

export const Expskill = () => {
  return (
    <div className="Expskill-box" id="skills-experience">
      <div className="container">
        <TitleBar
          BigTitle="Skills & Experience"
          Title="Skills & Experience"
          Para="Creative evolution through challenges, learning, innovation, and persistent dedication."
        />
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-6">
            <div className="skill-box">
              {Skilldata.map((skill) => {
                return (
                  <div className="skill-item wow pulse" key={skill.id}>
                    <img src={skill.img} className="img-fluid" alt="skill" />
                    <p>{skill.count}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="exp-box">
              {Expdata.map((exp) => {
                return (
                  <a
                    href={exp.expLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={exp.id}
                  >
                    <div className="exp-item wow bounceInRight">
                      <div className="exp-img">
                        <img src={exp.expImg} className="img-fluid" alt="exp" />
                      </div>
                      <div className="exp-data w-100">
                        <div className="exp-title d-flex justify-content-between">
                          <h1>{exp.expTitle}</h1>
                          <Exparrow />
                        </div>
                        <div className="exp-para d-flex justify-content-between">
                          <p>{exp.expRole}</p>
                          <p>{exp.expDate}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
