import React from "react";
import "./titlebar.css";

export const TitleBar = (props) => {
  return (
    <div className="TitleBar wow bounceIn">
      <div className="Title-container">
        <h1 className="Title-big">{props.BigTitle}</h1>
        <h2 className="Title-small">{props.Title}</h2>
      </div>
      <p className="Title-para">{props.Para}</p>
    </div>
  );
};
