import React from "react";
import { TitleBar } from "../../Atoms/Titlebar/TitleBar";
import { GraphicIcon, WebIcon, UiuxIcon, AppIcon } from "../../Atoms/svgfile";
import "./whatdo.css";

const ServiceData = [
  {
    id: 1,
    serviceIcon: <GraphicIcon />,
    serviceTitle: "Graphic Design",
    servicePara:
      "As a graphic designer, I visually communicate ideas and messages across diverse mediums, blending creativity and artistic skill with expertise in design principles and typography.",
  },
  {
    id: 2,
    serviceIcon: <WebIcon />,
    serviceTitle: "Web Design",
    servicePara:
      "As a web design professional, I create visually appealing, functional websites for businesses and individuals. Expertise includes latest coding languages, SEO, and user experience optimization.",
  },
  {
    id: 3,
    serviceIcon: <UiuxIcon />,
    serviceTitle: "UI/UX Design",
    servicePara:
      "As a UI/UX designer, I craft engaging experiences, understanding user needs to create visually appealing, intuitive interfaces. Process includes research, wireframing, prototyping, and testing.",
  },
  {
    id: 4,
    serviceIcon: <AppIcon />,
    serviceTitle: "App Design",
    servicePara:
      "As an app designer, I prioritize creating user-friendly, visually appealing interfaces. Success stems from understanding target audiences, user behavior, and industry trends.",
  },
];

export const Whatdo = () => {
  return (
    <div className="Service-box" id="service">
      <div className="container">
        <TitleBar
          BigTitle="What i do"
          Title="What i do"
          Para="how I may support your upcoming project"
        />
        <div className="row">
          {ServiceData.map((data) => {
            return (
              <div className="col-lg-3 col-md-6" key={data.id}>
                <div className="service-card wow bounceInUp">
                  <div className="service-icon">{data.serviceIcon}</div>
                  <h1 className="service-title">{data.serviceTitle}</h1>
                  <p className="service-para">{data.servicePara}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
