import React, { useState, useEffect } from "react";
import { Logo } from "../../Atoms/svgfile";
import "./header.css";
import Sidebar from "../../Atoms/Sidebar/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  return (
    <>
      <div
        className={
          scroll ? "header-box sticky-top shadow" : "header-box sticky-top"
        }
      >
        <nav className="navbar navbar-expand-sm navbar-light justify-content-between align-items-center wow fadeIn">
          <a className="navbar-brand" href="/">
            <Logo />
          </a>
          <ul className="navbar-nav align-items-center">
            <li className="nav-item">
              <a className="nav-link" href="tel:9354851584">
                <FontAwesomeIcon icon={faPhone} />
                +91 - 9354851584
              </a>
            </li>
            <li className="nav-item">
              <Sidebar />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
