import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  BehanceIcon,
  DribbbleIcon,
  InstagramIcon,
  LinkedinIcon,
  Logo,
  Menuicon,
} from "../svgfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./sidebar.css";

const Sidebar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const SidebarNavs = [
    {
      label: "about",
      Url: "#about",
    },
    {
      label: "portfolio",
      Url: "#portfolio",
    },
    {
      label: "Skills & Experience",
      Url: "#skills-experience",
    },
    {
      label: "Service",
      Url: "#service",
    },
  ];
  return (
    <>
      <button
        className="nav-link btn border-0"
        variant="primary"
        onClick={handleShow}
      >
        <Menuicon />
      </button>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Logo />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sidebar-navs">
            <ul className="nav flex-column">
              {SidebarNavs.map((data) => {
                return (
                  <li className="nav-item" key={data.id}>
                    <a
                      className="nav-link"
                      href={data.Url}
                      onClick={handleClose}
                      rel="noopener noreferrer"
                    >
                      {data.label}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <a
            href="https://wa.me/9354851584"
            target="_blank"
            className="btn btn-primary text-uppercase"
            rel="noopener noreferrer"
          >
            Hire me
          </a>
          <p className="sidebar-para">
            I am a UX Designer, Over the past 5+ years I&apos;ve created
            well-crafted mobile and web apps by connecting Business goals with
            user needs.
          </p>
          <div className="follow-box">
            <h3>Follow</h3>
            <div className="follow-row">
              <a
                href="https://www.linkedin.com/in/aman-mishra-800402192/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>
              <a
                href="https://www.instagram.com/its_a_professional/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.behance.net/amanmishra2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BehanceIcon />
              </a>
              <a
                href="https://dribbble.com/AmanDesigner"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DribbbleIcon />
              </a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
