import React from "react";
import "./hireme.css";

export const Hireme = () => {
  return (
    <div className="Hire-box">
      <div className="hire-animation">
        <img
          src="/images/hire-shape-1.png"
          className="hire-shape-1"
          alt="animation"
        />
        <img
          src="/images/hire-shape-2.png"
          className="hire-shape-2"
          alt="animation"
        />
        <img
          src="/images/hire-shape-3.png"
          className="hire-shape-3"
          alt="animation"
        />
        <img
          src="/images/hire-shape-4.png"
          className="hire-shape-4"
          alt="animation"
        />
      </div>
      <div className="container">
        <div className="hire-container text-center">
          <h1 className="wow bounceInUp">Interested in working with me?</h1>
          <a
            href="https://wa.me/9354851584"
            target="_blank"
            className="btn btn-primary text-uppercase wow bounceIn"
            rel="noopener noreferrer"
          >
            Hire ME
          </a>
        </div>
      </div>
    </div>
  );
};
