import React from "react";
import "./banner.css";
const Banner = () => {
  return (
    <div className="banner-container">
      <div className="row h-100">
        <div className="col-md-1 banner-contact wow bounceInDown">
          <a
            href="https://wa.me/9354851584"
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact with me{" "}
          </a>
        </div>
        <div className="col-md-11">
          <div className="banner-box">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="banner-left wow bounceIn">
                    <h5>Aman Mishra</h5>
                    <h1>
                      UI/UX <br /> Designer
                    </h1>
                    <a
                      href="/images/Aman_Resume.pdf"
                      className="btn btn-primary"
                      download
                    >
                      Download CV
                    </a>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="banner-right wow fadeIn">
                    <img
                      src="/images/user-img.png"
                      className="img-fluid"
                      alt="Profile Photo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/images/slider-shape-1.png"
              className="banner-animation1"
              alt="animation"
            />
            <img
              src="/images/slider-shape-2.png"
              className="banner-animation2"
              alt="animation"
            />
            <img
              src="/images/slider-shape-3.png"
              className="banner-animation3"
              alt="animation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
