import React, { useState, useEffect } from "react";
import "./App.css";
import Loader from "./Atoms/Loader/Loader";
import Header from "./Component/Header/header";
import Banner from "./Component/Banner/banner";
import { About } from "./Component/About/About";
import { Work } from "./Component/Work/Work";
import { Expskill } from "./Component/ExpSkill/ExpSkill";
import { Whatdo } from "./Component/Services/Whatdo";
import { Hireme } from "./Component/Hireme/Hireme";
import Footer from "./Component/Footer/Footer";
import "wow.js/css/libs/animate.css";

const isServer = typeof window === "undefined";
const WOW = !isServer ? require("wow.js") : null;

function App() {
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 1000);
  }, []);

  useEffect(() => {
    new WOW().init();
  }, []);

  return spinner ? (
    <Loader />
  ) : (
    <>
      <Header />
      <Banner />
      <About />
      <Work />
      <Expskill />
      <Whatdo />
      <Hireme />
      <Footer />
    </>
  );
}

export default App;
