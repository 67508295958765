import React from "react";
import { LoaderLogo } from "../svgfile";
import "./loader.css";

const Loader = () => {
  return (
    <section className="mainloader-container">
      <LoaderLogo />
      <div class="loader3">
        <span></span>
        <span></span>
      </div>
    </section>
  );
};

export default Loader;
