import React from "react";
import { BehanceIcon, DribbbleIcon, WhatsAppIcon } from "../../Atoms/svgfile";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer-box">
      <div className="container">
        <div className="row align-items-center wow fadeIn">
          <p className="copyright-text col-md-8 col-lg-6">
            Copyright © {new Date().getFullYear()} by{" "}
            <strong>Aman Designer</strong> . All Rights Reserved.
          </p>
          <div className="social-row col-md-4 col-lg-6">
            <a
              href="https://www.behance.net/amanmishra2"
              target="_blank"
              className="nav-link"
              rel="noopener noreferrer"
            >
              <BehanceIcon />
            </a>
            <a
              href="https://dribbble.com/AmanDesigner"
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DribbbleIcon />
            </a>
            <a
              href="https://wa.me/9354851584"
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
